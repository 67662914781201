<template>
  <div>
    <section-banner
      v-for="(section, index) in sections"
      :key="index"
      :model="section"
    ></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: "FamilyLaw",
  components: {
    SectionBanner,
  },
  data() {
    return {
      sections: [
        {
          Name: "What we do",
          Title: "Family Law",
          Subtitle: "Untangling the ties that bind",
          Description:
            "Issues arise within any family. We understand that, and can help you and the people that matter most to you to work through matters with care, respect, and compassion.",
          ctaButton: "",
          ctaLink: "",
          img: require("@/assets/img/Helanie.jpg"),
          white: false,
          justifyContentRight: true,
        },
        {
          TextColumn: true,
          Decoration: require("@/assets/img/Commas.svg"),
          TextColumn1: `<p>Whether it’s mediation, a contract, or just red tape, we can assist with:</p>
            <ul>
              <li>Drafting of Antenuptial Contracts</li>
              <li>Amending the matrimonial property regime to reflect Postnuptial Contracts</li>
              <li>Instituting or Defending Divorces</li>
              <li>Maintenance</li>
              <li>Assisting you with regard to Parental Rights and Responsibilities</li>
              <li>Assisting you with regard to Care and Contact towards a minor child and/or children</li>
              <li>Instituting or defending Rule 43 Applications</li>
              <li>Instituting or defending Urgent Applications</li>
              <li>Instituting or defending Guardianship Applications</li>
              <li>Drafting Agreements necessary for travelling overseas with a minor child and/or children</li>
              <li>Assisting and advising on Adoptions</li>
            </ul>`,
          TextColumn2:
            "<blockquote>Thanks again for all your hard work, and your understanding and caring attitude.”<br> <br>“Thank you. Much appreciated.”<br> <br>“Baie dankie vir al jou moeite, jy sal nooit weet hoeveel ek dit waardeer nie.”<br> <br>“Thank you for your never ending patience and advice. I appreciate your help.”<br> <br>“Thank you. Looking forward to many more laughs and good times.”<br> <br>“Dankie vir die fantastiese diens!”<br> <br>“Thank you for your advice.”<br> <br>“Thank you so so so so so much for everything you did (today). I’m really so grateful for all your hard work.”</blockquote>",
        },
        {
          Profiles: true,
          Name: "Talk to Us",
          Bios: [
            {
              Title: "Helani van der Westhuizen",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/helani-van-der-westhuizen",
              Email: "helani@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Helanie-bio.jpg"),
              left: true,
            },
            {
              Title: "Touche Munyai",
              Subtitle: "Candidate Attorney",
              Linkedin: "linkedin.com/in/avhurengwitouchemunyai",
              Email: "touche@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Touche-bio.jpg"),
              left: false,
              last: true,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
